import 'react-app-polyfill/stable.js';

if (process.env.NODE_ENV === 'production') {
    console.debug = () => void 0;
}

function importBuildTarget() {
    if (process.env.REACT_APP_BUILD_TARGET === 'lp') {
        return import('./lp');
    } else {
        return import('./app');
    }
}

// Decides which target will be built
importBuildTarget();
export {};
